/* ==========================================================================
   TQL Contrast skin
   ========================================================================== */

/* Colors */
$text-color: #000 !default;
$muted-text-color: $text-color !default;
/* $primary-color: #ff0000 !default; */
/* $primary-color: #6667ab !default; */ /* Very Peri */
/*$primary-color: #BE3455 !default; */ /* Viva Magenta */
/*$primary-color: #FFBE98 !default; */ /* Peach Fuzz */
$primary-color: #A47864 !default;  /* Mocha Mousse 2025 */
$border-color: mix(#fff, $text-color, 75%) !default;
$footer-background-color: #ddd !default;
$link-color: #0000dd !default;
$masthead-link-color: $text-color !default;
$masthead-link-color-hover: $text-color !default;
$navicon-link-color-hover: mix(#fff, $text-color, 80%) !default;

/* FONT */
$img-font: "Trebuchet MS", Helvetica, sans-serif;


/* contrast syntax highlighting (base16) */
$base00: #000000 !default;
$base01: #242422 !default;
$base02: #484844 !default;
$base03: #6c6c66 !default;
$base04: #918f88 !default;
$base05: #b5b3aa !default;
$base06: #d9d7cc !default;
$base07: #fdfbee !default;
$base08: #ff6c60 !default;
$base09: #e9c062 !default;
$base0a: #ffffb6 !default;
$base0b: #a8ff60 !default;
$base0c: #c6c5fe !default;
$base0d: #96cbfe !default;
$base0e: #ff73fd !default;
$base0f: #b18a3d !default;

.page__content {
  .notice,
  .notice--primary,
  .notice--info,
  .notice--warning,
  .notice--success,
  .notice--danger {
    color: $text-color;
  }
}

.page__footer {
  color: #112 !important; // override
}

.page__footer-follow .social-icons i,
.page__footer-follow .social-icons .svg-inline--fa  {
  color: inherit;
}
