@charset "utf-8";

@import "minimal-mistakes/skins/tql"; // skin
@import "minimal-mistakes"; // main partials

//######################################
// Fonts and sizes
//######################################
@font-face {
    font-family: 'ITC-Charter-Pro';
    src: url('/assets/css/ITC-Charter-Pro-font.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: Garmond, "Times New Roman", ITC-Charter-Pro, serif;
 }

html {
  font-size: 17px; // change to whatever

  @include breakpoint($medium) {
    font-size: 18px; // change to whatever
  }

  @include breakpoint($large) {
    font-size: 19px; // change to whatever
  }

  @include breakpoint($x-large) {
    font-size: 20px; // change to whatever
  }
}

@import "https://unpkg.com/image-compare-viewer/dist/image-compare-viewer.min.css";

@import "tql_color";
@import "tql_boxes";
@import "tql_styling";
