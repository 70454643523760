// TQL CSS
//

img.product-img {
   width: 100px;
   height: 100px;
   object-fit: contain;
}

hr.essay-sep {
   width: 100%;
   margin: auto;
}

.mySlides img {
  width: 800px;
  height: 400px; 
  object-fit: contain; 
}

div.signature{
   opacity: 0.8;
}

div.relatedPosts{
   opacity: 0.7;
   h2 {
      font-size: 1.2em;
   }
   clear: both;
}

div.tql-center {
   text-align: center;
      opacity: 50%;
}

button.img-button {

}

button.tql-active {
   background-color: #AFAFFF;
}

span.tag-label {
   border: 1px solid black;
}

ul.excerpt_list {
   list-style-type: none;
   margin: 0;
   padding: 0;
   li:last-child{
      margin-bottom: 1em;
   }
}

ul.portfolio-gallery {
   list-style-type: none;
   margin: 0;
   padding: 0;
   
   li {
      float: left;
   }
   
   li:last-child {

   }
}

a.title-link {
   font-weight: bold;
   font-style: italic;
//   font-size: 1.2em;
}

.post-list a {
   font-weight: bold;
}

.excerpt_list li hr {
   border-top: 1px solid #ddd;
   border-bottom: 0px;
   border-left: 0px;
   border-right: 0px;
}

blockquote {
   @include quote-box;   
   font-style: italic;
}

.portfolio-thumb  li img {
  @include box-shadow;
}

.portfolio-thumb  li {
  
}

div.EssayNavigation {
   @include left-right-box;   
}

div.EssayNavigation:after {
   content: "";
   display: table;
   clear: both;
}

div.EssayNavigation2 {
   @include left-right-box;   
   div.right {
      opacity: 0.7;
      float: none;
   }
}

div.EssayNavigation2:after {
   content: "";
   display: table;
   clear: both;
}

div.ImageNavigation {
   font-size: 3em;
   .right{
      float:right;
   }
   
   .left{
      float:left;
   }
   a {
      text-decoration: none;
   }
   
   img {
      width: 50px;
      height: 50px;
      object-fit: contain;
   }
}

.post-list {   
   > li {
      margin-bottom: 0em;
   }
}

div.caption {
   font-family: $img-font;
   font-weight: lighter;
   font-size: .85em;
   color: $picture-foreground-color;
}

div.caption-thumb {
   margin-bottom: 1em;
   font-family: $img-font;
   font-weight: lighter;
   font-size: .7em;
}

div.time-place {
   font-family: $img-font;
   font-weight: lighter;
   font-size: .7em;
}

div.names {
   font-family: $img-font;
   font-weight: lighter;
   font-size: .7em;
}

div.caption-text {
   font-family: $img-font;
   font-weight: lighter;
   font-size: .85em;
}

div.picture {
   @include picture-box;
   img {
//      border: 3px;
//      border-style: inset;
//      boder-color: rgb(225,225,225);
   }
   margin-bottom: 1em;
}

div.weatherforecast {
   @include box-shadow;
}

div.uml {
   @include quote-box;
   margin-bottom: 1em;
}

div.portfolio-thumb {
   width: 200px;
   height: 200px;
   margin: 0.5em;

   img {
      @include box-shadow;
   }
}

figcaption {
   font-family: $img-font;
   font-weight: lighter;
   font-size: .85em;
   color: $picture-foreground-color;
}

div.picture-thumb {
   width: 200px;
   max-height: 200px;
   margin: 1em;
  
   img {
      @include box-shadow;
   }
}

div.exif {
   font-size: .7em;
   color: $picture-foreground-color;
}

.tql-map {
   @include picture-box;
}


// TOC nav box header.
//.toc .nav__title {
//    background: grey;
//}

img.signature {
/*   opacity: 0.7; */
   width: 120px;
}

p.article-excerpt {
   font-size: 0.9em;
   color: $excerpt-foreground-color;
}

// ==============================================
// Transluscent overlay large images
// ==============================================

.mfp-bg {
  background: #fff;
  opacity: 0.96;
}

// "1 of X" counter
.mfp-counter {
  color: #333;
}

.mfp-title {
   color: #333;
}

.mfp-arrow-left {
    &:after,
    .mfp-a {
      opacity: 0.3;
      border-right: 17px solid #000;
    }
    &:before,
    .mfp-b {
      opacity: 0.3;
      border-right: 27px solid #fff;
    }
}

.mfp-arrow-right {
    &:after,
    .mfp-a {
      opacity: 0.3;
      border-left: 17px solid #000;
    }
    &:before,
    .mfp-b {
      opacity: 0.3;
      border-left: 27px solid #fff;
    }
}
// Close icon
.mfp-close {
  opacity: 1;
  color: #333;
}
.mfp-close-btn-in {
  .mfp-close {
    color: #fff;
  }
}
.mfp-image-holder,
.mfp-iframe-holder {
  .mfp-close {
    color: #333;
  }
}
