//######################################
// Boxes
//######################################
@mixin rounded-corners ($radius: 5px) {
   -webkit-border-radius: $radius;
   -moz-border-radius: $radius;
   -ms-border-radius: $radius;
   -o-border-radius: $radius;
   border-radius: $radius;
}

@mixin relief-box ($color: $quote-background-color, $fgcolor: $quote-foreground-color) {
      color: $fgcolor;
      background-color: $color;
      border-top: 1px solid lighten($color, 5%);
      border-left: 1px solid lighten($color, 5%);
      border-bottom: 1px solid darken($color, 5%);
      border-right: 1px solid darken($color, 5%);

      border-top: 1px solid darken($color, 5%);
      border-left: 1px solid darken($color, 5%);
      border-bottom: 1px solid darken($color, 10%);
      border-right: 1px solid darken($color, 10%);

}

@mixin box-shadow ($x: 3px, $y: 3px, $blur: 5px, $alpha: 0.5) {
      -webkit-box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
      -moz-box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
      box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
}

@mixin quote-box {
   @include relief-box($quote-box-background-color, $quote-foreground-color);
   @include rounded-corners;
   @include box-shadow;
   padding-top: 1em;
   padding-bottom: 1em;
   padding-right: 2em;
   padding-left: 2em;
}

@mixin picture-box {
   @include relief-box($picture-box-background-color, $picture-foreground-color);
   @include box-shadow;
   padding-top: 1em;
   padding-bottom: 0em;
   padding-left: 1em;
   padding-right: 1em;
   margin: 0em;
   margin-bottom: 0em;
   
}

@mixin portfolio-thumb-box {
   @include relief-box($picture-box-background-color, $picture-foreground-color);
   @include box-shadow;
   padding-top: 0em;
   padding-bottom: 0em;
   padding-left: 0em;
   padding-right: 0em;
   width: 200px;
   height: 200px;
   margin: 0.5em;
}

@mixin left-right-box {
   div.right{
      float: right;
   }
   
   div.left{
      float:left;
      padding-right: 1.5em; 
   }   
}