$foreground-color: #333;
$background-color: #fff;

$header-foreground-color: #030;
$header-background-color: #ddd;

$title-foreground-color: #020;
$title-background-color: #ccc;

$quote-foreground-color: #222;
$quote-box-background-color: #eee;

$picture-foreground-color: #999;
$picture-box-background-color: #fff;

$excerpt-foreground-color: #020;

/* $tql-primary-color: #6667ab !default; */ /* Very Peri 2022 */
/* $tql-primary-color: #BE3455 !default; */ /* Viva Magenta 2023 */
/* $tql-primary-color: #FFBE98 !default; */ /* Peach Fuzz 2024 */

$tql-primary-color: #A47864 !default;  /* Mocha Mousse 2025 */
